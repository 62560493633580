<template lang="pug">
.rooms-bar
    .title-text(v-if="!hideTitle")
        p Our Rooms
    .container
        .room-viewer
            .room
                img(src="https://s3.starfall-lodge.com/img/dt_king2_small.jpg")
                p.darktext king / twin
                router-link.greentext(to="/rooms/dk") Check Details
            .room
                img(src="https://s3.starfall-lodge.com/img/quad_king_small.jpg")
                p.darktext Quad
                router-link.greentext(to="/rooms/quad") Check Details
            .room
                img(src="https://s3.starfall-lodge.com/img/4bed_small.jpg")
                p.darktext Mixed Dorm
                router-link.greentext(to="/rooms/dorm") Check Details



</template>

<style scoped>
.rooms-bar {
    background: white;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.title-text {
    display: grid;
    place-items: center;
    padding-bottom: 0.5rem;
}

.title-text p {
    font-family: ModestoOpenInfill, sans-serif;
    font-size: 36px;
}

.room-viewer {
    display: grid;
    grid-gap: 3rem;
    text-align: center;
}

.room-viewer a:hover {
    text-decoration: underline;
    color: #28e5c5;
}

.room img {
    width: 100%;
}

@media screen and (min-width: 769px) {
    .room-viewer {
        text-align: left;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        grid-gap: 1rem;
    }
    .room-viewer img, .room-viewer p {
        padding-bottom: 1rem;
    }
}
</style>

<script>
export default {
    name: "RoomsBar",
    props: ['hideTitle']
}
</script>


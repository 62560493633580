<template lang="pug">
.booking-engine
    .container
      //p {{bookingURL}}
      //p.has-text-centered.greentext Coming VERY, VERY soon...
      #zak-booking-iframe

</template>

<style scoped>
#zak-booking-iframe {
    width: 100%;
    min-height: calc(100vh - 7rem);
}
</style>

<script>
export default {
    name: "Book",
    data: function () {
        return {
            bookingURL: "https://wubook.net/neb/bk?"
        }
    },
    activated: function () {
        const targetDiv = '#zak-booking-iframe';
        let iframeURL = "https://wubook.net/nneb/bk?ep=674e33ad";
        iframeURL += `&f=${encodeURIComponent(this.$route.query.dateFrom ? this.$route.query.dateFrom : '20/02/2024')}`;
        iframeURL += `&n=${this.$route.query.nights ? this.$route.query.nights : '4'}`;
        iframeURL += `&o=1.0.0.0`;

        this.bookingURL = iframeURL;
        const Neb = new ZakNebIframe(targetDiv, iframeURL);
        Neb.render();
    }
}
</script>

